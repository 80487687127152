import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';
import {AuthService} from '@app/modules/pages/login/auth.service';
import { environment } from 'environments/environment';
import { ToastService } from '@core/services/toast.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  private peerConnection!: RTCPeerConnection;
  public stream!: MediaStream;
  public isStreaming = false;
  private peerId = uuidv4();

  currentUser: any;
  protected streamParam: string;
  private streamSubject = new BehaviorSubject<MediaStream | null>(null);
  public stream$ = this.streamSubject.asObservable();
  private isStreamingSubject = new BehaviorSubject<boolean>(false);
  public isStreaming$ = this.isStreamingSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _authenticationService: AuthService,
    private _toastService: ToastService,
  ) {

  }

  startLiveStreaming() {
    navigator.mediaDevices.getUserMedia({video:true, audio:true})
      .then((stream: MediaStream) => {
        this.stream = stream;
        this._authenticationService.currentUser.subscribe(currentUser => {
          this.currentUser = currentUser;
          this.streamParam = currentUser['id'];
        });
        this.initWebRTC();
      })
      .catch(error => {
        console.error('Kameraga kirish ruxsati rad etildi yoki boshqa xato yuz berdi:', error);
        if(error){
          this._toastService.warning("TOAST.CameraNotAllowed")
        }
      });
  }

  stopLiveStreaming() {
    console.log("STOPLING STOPLING");
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = undefined as any;
    }

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = undefined as any;
    }

    if (this.stream) {
      this.stream = null;
      this.streamSubject.next(null);
      this.isStreamingSubject.next(false);
    }
    sessionStorage.removeItem('isStreaming');
  }

  // stopLiveStreaming() {
  //   // WebRTC PeerConnection'ni to‘xtatish
  //   if (this.peerConnection) {
  //     this.peerConnection.close();
  //     this.peerConnection = undefined as any;
  //   }
  //
  //   // MediaStream'ni to‘xtatish
  //   if (this.stream) {
  //     this.stream.getTracks().forEach(track => track.stop());
  //     this.stream = undefined as any;
  //   }
  //
  //   // Video elementni tozalash
  //   if (this.videoElement) {
  //     this.videoElement.nativeElement.srcObject = null;
  //   }
  //
  //   sessionStorage.removeItem('isStreaming');
  // }
  reconnectionInterval;
  private initWebRTC() {
    const config = {
      iceServers: []
    };
    this.peerConnection = new RTCPeerConnection(config);
    // Local streamni PeerConnection'ga qo'shish
    this.stream.getTracks().forEach(track => {
      this.peerConnection.addTrack(track, this.stream);
    });

    this.peerConnection.addEventListener('connectionstatechange', event => {
      switch (this.peerConnection.connectionState) {
        case 'connected':
          // Emit the active stream when connection is successful
          this.streamSubject.next(this.stream);
          this.isStreamingSubject.next(true);
          if (this.reconnectionInterval) {
            clearInterval(this.reconnectionInterval);
            this.reconnectionInterval = null;
          }
          break;
        case 'disconnected':
        case 'failed':
          // Notify stream is inactive when connection is lost
          this.streamSubject.next(null);
          this.isStreamingSubject.next(false);
          this.peerConnection.close();
          if (!this.reconnectionInterval) {
            this.reconnectionInterval = setInterval(() => {
              this.initWebRTC();
            }, 4000);
          }
          break;
        default:
          // Optional: handle other states (new, checking, connecting, etc.)
          break;
      }
    });
    this.peerConnection.createOffer()
      .then(offer => {
        const sdp = offer.sdp;
        const vp9Codec = 'VP9/90000';

        // Use regex to reorder SDP lines to prioritize VP9
        const lines = sdp.split('\r\n');
        const mLineIndex = lines.findIndex(line => line.startsWith('m=video'));
        const codecLines = lines.filter(line => line.includes(vp9Codec));

        if (codecLines.length > 0 && mLineIndex !== -1) {
          const vp9Payload = codecLines[0].match(/(\d+)/)[0];
          const mLineParts = lines[mLineIndex].split(' ');
          const videoCodecPayloads = mLineParts.slice(3); // Video payloads after 'm=video ...'

          // Prioritize VP9 by moving its payload to the beginning
          mLineParts.splice(3, videoCodecPayloads.length, vp9Payload, ...videoCodecPayloads.filter(payload => payload !== vp9Payload));
          lines[mLineIndex] = mLineParts.join(' ');
        }
        offer.sdp = lines.join('\r\n');
        return this.peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        return fetch(environment.apiUrl + '/api/v1/whip/' + this.streamParam , {
          method: 'POST',
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/sdp'
          },
          body: this.peerConnection.localDescription?.sdp || ''
        });
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Server response: ${response.status}`);
        }
        return response.text();
      })
      .then(answerSDP => {
        // Remote Description ni o'rnatish
        const remoteDesc = new RTCSessionDescription({
          type: 'answer',
          sdp: answerSDP
        });
        return this.peerConnection.setRemoteDescription(remoteDesc);

      })
      .catch(error => {
        console.error('Server bilan ulanishda xato:', error);
        if(error){
          this._toastService.error("Components.CallCenter.LiveVideoServerError" )
        }
      });
  }
}
